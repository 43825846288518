<template>
  <div>
    <vue-loading-overlay
      ref="loadingOverlay"
      :active.sync="isLoading"
      loader="dots"
      :is-full-page="true"
    />
    <flash-message class="flashpool custom-flash" transitionName="flash" />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters("dataComponent", {
      isLoading: "getIsLoading",
    }),
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";

.flashpool {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  top: 10px;
  right: 20px;
  max-height: 500px;
  min-width: 300px;
  max-width: 500px;
  -webkit-perspective: 500px;
  perspective: 500px;
}

.flashpool .flash__message {
  min-width: 300px;
  max-width: 500px;
  -webkit-transition: all 500ms;
  -o-transition: all 500ms;
  transition: all 500ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Oxygen", sans-serif;
  font-size: 13px;
  line-height: 130%;
}

.flash-enter,
.flash-leave-to {
  opacity: 0;
  -webkit-transform: rotateX(-30deg) scale(0.88) translateY(-30px);
  transform: rotateX(-30deg) scale(0.88) translateY(-30px);
}

.flash-leave-active {
  position: absolute;
}

.custom-flash {
  top: 65px;
  right: 32px;
  z-index: 2;
}
</style>
