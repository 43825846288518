import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store.js'
import TheSidebarNavMenu from '@/components/TheSidebarNavMenu'

const baseUrlRouter = process.env.VUE_APP_URL_ROUTER

const baseUrl = baseUrlRouter

// Containers
const TheContainer = () => import('@/components/TheContainer')

// Views
// const Dashboard = () => import('@/views/Dashboard')

// Master
// const MasterJadwalUjian = () => import('@/views/master/MasterJadwalUjian')
// const MasterSekolah = () => import('@/views/master/MasterSekolah')

// Master Mpt
// const JabatanStrukturalMpt = () => import('@/views/master-mpt/JabatanStrukturalMpt')
// const PanitiaMpt = () => import('@/views/master-mpt/PanitiaMpt')
// const GrupMpt = () => import('@/views/master-mpt/GrupMpt')

// Transaksi Mpt
// const GenerateGrupMpt = () => import('@/views/transaksi-mpt/GenerateGrupMpt')
// const GenerateGrupOutboundMpt = () => import('@/views/transaksi-mpt/GenerateGrupOutboundMpt')
// const PenyesuaianPesertaGrupMpt = () => import('@/views/transaksi-mpt/PenyesuaianPesertaGrupMpt')
// const PesertaMptGagal = () => import('@/views/transaksi-mpt/PesertaMptGagal')

// Transaksi
// const RegistrasiAkunCalonMahasiswa = () => import('@/views/transaksi/RegistrasiAkunCalonMahasiswaPage')
// const ImportVirtualAccount = () => import('@/views/transaksi/ImportVirtualAccountPage')
// const VerifikasiPembayaranVirtualAccount = () => import('@/views/transaksi/VerifikasiPembayaranVirtualAccountPage')
const KonfigurasiMarketer = () => import('@/views/transaksi/KonfigurasiMarketerPage')

// const Pendaftaran = () => import('@/views/transaksi/PendaftaranPage')

// Fitur jalur USM ( S1, S2 )
// const ImportHasilUsm = () => import('@/views/transaksi/ImportHasilUsmPage')
// const KonfigurasiPenilaianUsm = () => import('@/views/transaksi/KonfigurasiPenilaianUsmPage')

// Fitur Jalur Seleksi@click="onClickAdd"
// const VerifikasiMahasiswaSeleksi = () => import('@/views/transaksi/VerifikasiMahasiswaSeleksiPage')
// const DetailVerifikasiMahasiswaSeleksi = () => import('@/views/transaksi/DetailVerifikasiMahasiswaSeleksiPage')
// const ProsesSeleksiCalonMahasiswa = () => import('@/views/transaksi/ProsesSeleksiCalonMahasiswaPage')

// const PindahJurusanDanWaktuKuliah = () => import('@/views/transaksi/PindahJurusanDanWaktuKuliahPage')
// Fitur S1, S2 utk verify mhs yang melakukan DU pada web /daftar
// const VerifikasiDaftarUlang = () => import('@/views/transaksi/VerifikasiDaftarUlangPage')
// const DetailVerifikasiDaftarUlang = () => import('@/views/transaksi/DetailVerifikasiDaftarUlangPage')

// const DaftarUlang = () => import('@/views/transaksi/DaftarUlangPage')
// const IsiUbahDataLengkapMahasiswa = () => import('@/views/transaksi/IsiUbahDataLengkapMahasiswaPage')

// Laporan
// const LaporanDaftarPendaftar = () => import('@/views/laporan/LaporanDaftarPendaftar')
// const LaporanJumlahMahasiswaBerdasarkanSekolah = () => import('@/views/laporan/LaporanJumlahMahasiswaBerdasarkanSekolah')
const LaporanMarketingAgent = () => import('@/views/laporan/LaporanMarketingAgent')
const LaporanDaftarUlangHarian = () => import('@/views/laporan/LaporanDaftarUlangHarian')
const LaporanPendaftarUSMHarian = () => import('@/views/laporan/LaporanPendaftarUSMHarian')

// Laporan Mpt
// const LaporanPesertaMpt = () => import('@/views/laporan-mpt/LaporanPesertaMpt')
// const LaporanUlangTahunPesertaMpt = () => import('@/views/laporan-mpt/LaporanUlangTahunPesertaMpt')
// const LaporanKelulusanPesertaMpt = () => import('@/views/laporan-mpt/LaporanKelulusanPesertaMpt')
// const LaporanAbsensiDanUkuranJasDetailMpt = () => import('@/views/laporan-mpt/LaporanAbsensiDanUkuranJasDetailMpt')
// const LaporanUkuranJasRekapMpt = () => import('@/views/laporan-mpt/LaporanUkuranJasRekapMpt')
// const LaporanNonGrupMpt = () => import('@/views/laporan-mpt/LaporanNonGrupMpt')
// const LaporanGagalDanBelumIkutMpt = () => import('@/views/laporan-mpt/LaporanGagalDanBelumIkutMpt')

// Ultility (settings)
// const Register = () => import('@/views/ultility/Register')
// const UserRolesAssignment = () => import('@/views/ultility/UserRolesAssignment')
// const MenuPermissionAssignment = () => import('@/views/ultility/MenuPermissionAssignment')
// const ChangePassword = () => import('@/views/ultility/ChangePassword')

// Testing
// const PingTest = () => import('@/views/testing/PingTest')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Maintenance = () => import('@/views/pages/Maintenance')
// const ForgetPassword = () => import('@/views/pages/ForgetPassword')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  base: baseUrl,
  linkActiveClass: 'active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: configRoutes()
})

function configRoutes() {
  return [{
      path: '/',
      redirect: '/transaksi/konfigurasi-marketer',
      name: 'home',
      component: TheContainer,
      children: [
        // {
        //   path: 'dashboard',
        //   name: 'Dashboard',
        //   component: Dashboard,
        //   meta: {
        //     requiredAuth: true
        //   }
        // },
        // {
        //   path: 'master',
        //   name: 'Master',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [{
        //       path: 'master-jadwal-ujian',
        //       name: 'MasterJadwalUjian',
        //       component: MasterJadwalUjian
        //     },
        //     {
        //       path: 'master-sekolah',
        //       name: 'MasterSekolah',
        //       component: MasterSekolah
        //     },
        //   ]
        // },
        // {
        //   path: 'master-mpt',
        //   name: 'MasterMpt',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [{
        //       path: 'jabatan-struktural-mpt',
        //       name: 'JabatanStrukturalMpt',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: JabatanStrukturalMpt
        //     },
        //     {
        //       path: 'panitia-mpt',
        //       name: 'PanitiaMpt',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: PanitiaMpt
        //     },
        //     {
        //       path: 'grup-mpt',
        //       name: 'GrupMpt',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: GrupMpt
        //     },
        //   ]
        // },
        // {
        //   path: 'transaksi-mpt',
        //   name: 'TransaksiMpt',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [{
        //       path: 'generate-grup-mpt',
        //       name: 'GenerateGrupMpt',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: GenerateGrupMpt
        //     },
        //     {
        //       path: 'generate-grup-outbound-mpt',
        //       name: 'GenerateGrupOutboundMpt',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: GenerateGrupOutboundMpt
        //     },
        //     {
        //       path: 'penyesuaian-peserta-grup-mpt',
        //       name: 'PenyesuaianPesertaGrupMpt',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: PenyesuaianPesertaGrupMpt
        //     },
        //     {
        //       path: 'peserta-mpt-gagal',
        //       name: 'PesertaMptGagal',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: PesertaMptGagal
        //     },
        //   ]
        // },
        {
          path: 'transaksi',
          name: 'Transaksi',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
              path: 'konfigurasi-marketer',
              name: 'KonfigurasiMarketer',
              meta: {
                requiredAuth: true
              },
              component: KonfigurasiMarketer
            },
            // {
            //   path: 'registrasi-akun',
            //   name: 'RegistrasiAkun',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: RegistrasiAkunCalonMahasiswa
            // },
            // {
            //   path: 'import-virtual-account',
            //   name: 'ImportVirtualAccount',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: ImportVirtualAccount
            // },
            // {
            //   path: 'verifikasi-pembayaran-virtual-account',
            //   name: 'VerifikasiPembayaranVirtualAccount',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: VerifikasiPembayaranVirtualAccount
            // },
            // {
            //   path: 'pendaftaran',
            //   name: 'Pendaftaran',
            //   meta: {
            //     requiredAuth: true
            //   },
            //   component: Pendaftaran
            // },
            // {
            //   path: 'import-hasil-usm',
            //   name: 'ImportHasilUsm',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: ImportHasilUsm,
            // },
            // {
            //   path: 'konfigurasi-penilaian-usm',
            //   name: 'KonfigurasiPenilaianUsm',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: KonfigurasiPenilaianUsm,
            // },
            // {
            //   path: 'verifikasi-mahasiswa-seleksi',
            //   name: 'VerifikasiMahasiswaSeleksi',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: VerifikasiMahasiswaSeleksi
            // },
            // {
            //   path: 'detail-verifikasi-mahasiswa-seleksi/:idJalurSeleksi',
            //   name: 'DetailVerifikasiMahasiswaSeleksi',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: DetailVerifikasiMahasiswaSeleksi
            // },
            // {
            //   path: 'proses-seleksi-calon-mahasiswa',
            //   name: 'ProsesSeleksiCalonMahasiswa',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: ProsesSeleksiCalonMahasiswa
            // },
            // {
            //   path: 'pindah-jurusan-dan-waktu-kuliah',
            //   name: 'PindahJurusanDanWaktuKuliah',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: PindahJurusanDanWaktuKuliah,
            // },
            // {
            //   path: 'verifikasi-daftar-ulang',
            //   name: 'VerifikasiDaftarUlang',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: VerifikasiDaftarUlang
            // },
            // {
            //   path: 'detail-verifikasi-daftar-ulang/:idDaftarUlang',
            //   name: 'DetailVerifikasiDaftarUlang',
            //   // meta: {
            //   //   requiredAuth: true
            //   // },
            //   component: DetailVerifikasiDaftarUlang
            // },
            // {
            //   path: 'daftar-ulang',
            //   name: 'DaftarUlang',
            //   meta: {
            //     requiredAuth: true
            //   },
            //   component: DaftarUlang,
            // },
            // {
            //   path: 'isi-ubah-data-lengkap-mahasiswa',
            //   name: 'IsiUbahDataLengkapMahasiswa',
            //   meta: {
            //     requiredAuth: true
            //   },
            //   component: IsiUbahDataLengkapMahasiswa,
            // },
          ]
        },
        {
          path: 'laporan',
          name: 'Laporan',
          redirect: 'laporan/daftar-pendaftar',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            // {
            //   path: 'daftar-pendaftar',
            //   name: 'DaftarPendaftar',
            //   meta: {
            //     requiredAuth: true
            //   },
            //   component: LaporanDaftarPendaftar
            // },
            // {
            //   path: 'jumlah-mahasiswa-berdasarkan-sekolah',
            //   name: 'JumlahMahasiswaBerdasarkanSekolah',
            //   meta: {
            //     requiredAuth: true
            //   },
            //   component: LaporanJumlahMahasiswaBerdasarkanSekolah
            // },
            {
              path: 'laporan-marketing-agent',
              name: 'LaporanMarketingAgent',
              meta: {
                requiredAuth: true
              },
              component: LaporanMarketingAgent
            },
            {
              path: 'laporan-daftar-ulang-harian',
              name: 'LaporanDaftarUlangHarian',
              meta: {
                requiredAuth: true
              },
              component: LaporanDaftarUlangHarian
            },
            {
              path: 'laporan-pendaftar-usm-harian',
              name: 'LaporanPendaftarUSMHarian',
              meta: {
                requiredAuth: true
              },
              component: LaporanPendaftarUSMHarian
            },
          ]
        },
        // {
        //   path: 'laporan-mpt',
        //   name: 'LaporanMpt',
        //   redirect: 'laporan-mpt/peserta-mpt',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [{
        //       path: 'peserta-mpt',
        //       name: 'PesertaMpt',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: LaporanPesertaMpt
        //     },
        //     {
        //       path: 'ulang-tahun-peserta-mpt',
        //       name: 'UlangTahunPesertaMpt',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: LaporanUlangTahunPesertaMpt,
        //     },
        //     {
        //       path: 'kelulusan-peserta-mpt',
        //       name: 'KelulusanPesertaMpt',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: LaporanKelulusanPesertaMpt,
        //     },
        //     {
        //       path: 'absensi-ukuran-jas-detail',
        //       name: 'AbsensiUkuranJasDetail',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: LaporanAbsensiDanUkuranJasDetailMpt,
        //     },
        //     {
        //       path: 'ukuran-jas-rekap',
        //       name: 'UkuranJasRekap',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: LaporanUkuranJasRekapMpt,
        //     },
        //     {
        //       path: 'non-grup',
        //       name: 'NonGrup',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: LaporanNonGrupMpt,
        //     },
        //     {
        //       path: 'gagal-belum-ikut',
        //       name: 'GagalBelumIkut',
        //       meta: {
        //         requiredAuth: true
        //       },
        //       component: LaporanGagalDanBelumIkutMpt,
        //     }
        //   ]
        // },
      ]
    },
    // {
    //   path: '/register',
    //   name: 'Register',
    //   meta: {
    //     requiredAuth: true
    //   },
    //   component: Register
    // },
    // {
    //   path: '/user-roles-assignment',
    //   name: 'UserRolesAssignment',
    //   meta: {
    //     requiredAuth: true
    //   },
    //   component: UserRolesAssignment
    // },
    // {
    //   path: '/menu-permission-assignment',
    //   name: 'MenuPermissionAssignment',
    //   meta: {
    //     requiredAuth: true
    //   },
    //   component: MenuPermissionAssignment
    // },
    // {
    //   path: '/change-password',
    //   name: 'ChangePassword',
    //   meta: {
    //     requiredAuth: true
    //   },
    //   component: ChangePassword
    // },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: Maintenance
    },
    // {
    //   path: '/forget-password',
    //   name: 'ForgetPassword',
    //   component: ForgetPassword
    // },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    },
    {
      path: '/404',
      name: 'Page404',
      alias: '*',
      component: Page404
    },
  ]
}

// add authorization
// using Navigation Guards
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiredAuth) {
    // check login ?
    if (!store.getters['auth/isLoggedIn']) {
      router.app.$nextTick(() => {
        router.app.flash("You have to login", "warning")
        next({
          name: "Login"
        })
      })
      return 0
    } else {
      // req data user, menu 
      // try {
      //   let responseRoles = await store.dispatch("auth/requestDataRoles")
      //   if (responseRoles.status != 200) {
      //     let errorMessage = responseRoles.data.error.message
      //     await store.dispatch('auth/logout')
      //     router.app.$nextTick(() => {
      //       router.app.flash(errorMessage, "error")
      //       next({
      //         name: "Login"
      //       })
      //     })
      //     return 0
      //   }
      //   let responseDataUserMenu = await store.dispatch("auth/requestDataUserMenu")
      //   if (responseDataUserMenu.resDataUser.status != 200 || responseDataUserMenu.resMenuPermission.status != 200) {
      //     // jika data menu dan permisison terdapat error
      //     let errorMessage = responseDataUserMenu.resDataUser.data.error.message
      //     router.app.$nextTick(() => {
      //       router.app.flash(errorMessage, "error")
      //       next({
      //         name: 'Login'
      //       })
      //     })

      //     return 0
      //   }
      //   // data user dan menu permission tidak error
      //   if (to.name == 'ChangePassword' ||
      //     to.name == 'MenuPermissionAssignment' ||
      //     to.name == 'Register' ||
      //     to.name == 'UserRolesAssignment') {
      //     // khusus untuk menu ini skip permission
      //     next(true)
      //     return 0
      //   }
      //   let menuYangTersedia = [...TheSidebarNavMenu.allDataMenu]
      //   let menuYangBisaDiAkses = store.getters["auth/getMenu"]

      //   if (menuYangTersedia.length == 0) {
      //     router.app.$nextTick(() => {
      //       router.app.flash("Anda tidak memiliki akses akan fitur apapun", "warning")
      //       next({
      //         name: 'Login'
      //       })
      //     })
      //     return 0
      //   }
      //   let menuYangAkanDiakses = menuYangTersedia.find(it => it.routeName == to.name)
      //   if (!menuYangAkanDiakses) {
      //     // menu tidak tersedia
      //     next({
      //       name: 'Page404'
      //     })
      //     return 0
      //   }
      //   let idMenuYangAkanDiakses = menuYangAkanDiakses.id

      //   let getMenuDariYangBisaAkses = menuYangBisaDiAkses.find(it => it.id == idMenuYangAkanDiakses)
      //   if (!getMenuDariYangBisaAkses && to.name == 'Dashboard') {
      //     // do logout
      //     await store.dispatch("auth/logout")

      //     router.app.$nextTick(() => {
      //       router.app.flash("Anda tidak memiliki akses akan fitur dashboard", "warning")
      //       next({
      //         name: 'Login'
      //       })
      //     })
      //     return 0
      //   }
      //   if (!getMenuDariYangBisaAkses) {
      //     // jika tidak ditemukan berarti tidak memiliki hak akses
      //     store.dispatch("dataComponent/set", ["nowIdMenuClicked", 1])

      //     router.app.$nextTick(() => {
      //       router.app.flash("Anda tidak memiliki wewenang untuk masuk", "warning")
      //       next({
      //         name: 'Dashboard'
      //       })
      //     })
      //   } else {
      //     store.dispatch("dataComponent/set", ["nowIdMenuClicked", idMenuYangAkanDiakses])
      //     next(true)
      //   }
      // } catch (error) {
      //   router.app.$nextTick(() => {
      //     router.app.flash("Error Page : " + error, "error")
      //     next(true)

      //   })
      // }
      next(true)
    }
  } else {
    // jika sudah ter login di mana route nextnya login
    // redirect ke dashboard
    if (store.getters['auth/isLoggedIn'] && to.name === 'Login') {
      next({
        name: "KonfigurasiMarketer"
      })
      // next({
      //   name: "Dashboard"
      // })
    } else {
      next(true)
    }
  }
})

export default router