import axios from '@/axios';

// get konfigurasi TA Berjalan S1 dan S2
async function getKonfigurasiTahunAjaranBerjalan() {
    let endpoint = `konfigurasi-berjalan`
    let responseTahunAjaranBerjalan = await axios.get(endpoint)
    return responseTahunAjaranBerjalan
}

export default {
    getKonfigurasiTahunAjaranBerjalan,
}