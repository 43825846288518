import 'core-js/stable'
import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import CoreuiVue from '@coreui/vue'
import {
  iconsSet as icons
} from '@/assets/icons/icons.js'
import {
  BootstrapVue,
  IconsPlugin
} from 'bootstrap-vue'

import store from '@/store/store.js'

// mixins for global function
import '@/mixins/generalMixin.js'

import SmartTable from 'vuejs-smart-table'
// vue mask
import VueMask from 'v-mask'

// vue select 
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// vue masonry for making staggered grid view
import VueMasonry from 'vue-masonry-css';

// vue loading overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// flash message
import VueFlashMessage from 'vue-flash-message';
import 'vue-flash-message/dist/vue-flash-message.min.css';

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(CoreuiVue)
Vue.use(VueMask)
Vue.use(SmartTable)
Vue.use(VueMasonry)
Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 3000,
  }
});

Vue.component('v-select', vSelect)
Vue.component('vue-loading-overlay', Loading)

Vue.config.performance = true
Vue.prototype.$log = console.log.bind(console)
// for developer mode
Vue.config.debug = process.env.VUE_APP_DEBUG;
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS;

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})