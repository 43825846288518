// State
const state = {
    isLoading: false,
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    nowIdMenuClicked: 1,
}

// Getters
const getters = {
    getIsLoading: (state) => state.isLoading,
    getSidebarShow: (state) => state.sidebarShow,
    getSidebarMinimize: (state) => state.sidebarMinimize,
    getNowIdMenu: (state) => state.nowIdMenuClicked
}

// Actions
const actions = {
    doToggleSidebarMobile({commit}) 
    {
        commit('SET_SIDEBAR_MOBILE')
    },
    doToggleSidebarDesktop({commit}) 
    {
        commit('SET_SIDEBAR_DESKTOP')
    },
    set({commit}, [variable, value]) 
    {
        commit('SET_STATE', [variable, value])
    }
}

// Mutations
const mutations = {
    SET_SIDEBAR_DESKTOP(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    SET_SIDEBAR_MOBILE(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    SET_STATE(state, [variable, value]) {
        state[variable] = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}