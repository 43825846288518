import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// add presisted state
import createPersistedState from "vuex-persistedstate";

import SecureLS from "secure-ls";
var ls = new SecureLS({
  isCompression: false
});

// add modules
import auth from '@/store/modules/auth'
import dataComponent from '@/store/modules/dataComponent'


export default new Vuex.Store({
  modules: {
    auth,
    dataComponent,
  },
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
})