import axios from '@/axios';

async function getAllUsers() {
    let endpoint = `user?limit=all`;
    let resAllUsers = await axios.get(endpoint);
    return resAllUsers;
}

async function getUserById(idUser) {
    let endpoint = `user/${idUser}`;
    let resUser = await axios.get(endpoint);
    return resUser;
}

// untuk mengambil user punya roles
async function getUserRolesByIdUser(idUser) {
    let endpoint = `user/${idUser}/role?limit=all`;
    let resUserRoles = await axios.get(endpoint);
    return resUserRoles;
}

// untuk mengupdate user punya roles
async function updateUserRoles(idUser, roles) {
    let endpoint = `user/${idUser}/role`;
    let resUpdateUserRoles = await axios.put(endpoint, roles);
    return resUpdateUserRoles;
}

async function changeUserPassword(idUser, body) {
    let endpoint = `user/${idUser}/password`;
    let resChangePassword = await axios.patch(endpoint, body);
    return resChangePassword;
}

async function getHirarchyRolesByIdUser(idUser) {
    let endpoint = `user/${idUser}/hierarchy-role`
    let resHirarchyRoles = await axios.get(endpoint);
    return resHirarchyRoles;
}

async function getMenuByIdUser(idUser) {
    let endpoint = `user/${idUser}/menu?limit=all`;
    let resMenu = await axios.get(endpoint);
    return resMenu;
}

async function getPermissionByIdUserAndIdMenu(idUser, idMenu) {
    let endpoint = `user/${idUser}/menu/${idMenu}/permission?limit=all`;
    let resPermission = await axios.get(endpoint);
    return resPermission;
}

async function getForgetPasswordQuestionByUsername(username) {
    let endpoint = `user/forgot-password-question?username=${username}`;
    let resDataUserQuestion = await axios.get(endpoint);
    return resDataUserQuestion;
}

async function submitUserAnswer(idUser, body) {
    let endpoint = `user/${idUser}/forgot-password-question`;
    let resSubmitAnswer = await axios.post(endpoint, body);
    return resSubmitAnswer;
}

async function changeUserForgetPassword(idUser, body) {
    let endpoint = `user/${idUser}/new-password`;
    let resChangePassword = await axios.patch(endpoint, body);
    return resChangePassword;
}


export default {
    getAllUsers,
    getUserById,
    getUserRolesByIdUser,
    updateUserRoles,
    changeUserPassword,
    getHirarchyRolesByIdUser,
    getMenuByIdUser,
    getPermissionByIdUserAndIdMenu,
    getForgetPasswordQuestionByUsername,
    submitUserAnswer,
    changeUserForgetPassword
}