import axios from 'axios'
import store from '@/store/store.js'
import router from '@/router'

const baseUrlApi = process.env.VUE_APP_URL_API;

const baseURL = `${baseUrlApi}` + "/v1";

const service = axios.create({
    baseURL
})

// every request
service.interceptors.request.use(
    function (config) {
        const token = store.getters['auth/getToken'];
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    function (err) {
        return Promise.reject(err);
    }
)
// every response
service.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        if (error.response == undefined) {
            let errorMessage = "Terjadi Kesalahan pada Server API!!!"
            let responseError = {
                data: {
                    error: {
                        status: 500,
                        message: errorMessage
                    }
                }
            }
            return responseError
        }
        if (error.response.status == 401) {
            if (store.getters['auth/isLoggedIn']) {
                // refresh token apabila token dh habis
                try {
                    // req ulang yg sblmnya
                    let config = error.response.config
                    await store.dispatch("auth/refresh")
                    let token = store.getters['auth/getToken']
                    config.headers.Authorization = `Bearer ${token}`
                    return await axios(config)

                } catch (err) {
                    if (store.getters['auth/isLoggedIn']) {
                        await store.dispatch("auth/logout")
                    }
                    if (router.currentRoute.name != 'Login') {
                        router.replace({
                            name: "Login"
                        });
                    }
                }
            }
        }
        return error.response
    }
);

export default service