export default {
    allDataMenu: [{
            id: 1,
            nama: "Konfigurasi Marketing Agent",
            icon: "cil-pencil",
            group: "transaksi",
            routeName: 'KonfigurasiMarketer'
        },
        {
            id: 2,
            nama: "Laporan Marketing Agent",
            icon: "cil-list",
            group: "laporan",
            routeName: 'LaporanMarketingAgent'
        },
        {
            id: 3,
            nama: "Laporan Daftar Ulang Harian",
            icon: "cil-list",
            group: "laporan",
            routeName: 'LaporanDaftarUlangHarian'
        },
        {
            id: 4,
            nama: "Laporan Pendaftar USM Harian",
            icon: "cil-list",
            group: "laporan",
            routeName: 'LaporanPendaftarUSMHarian'
        },
        
        // {
        //     id: 1,
        //     nama: "Dashboard",
        //     icon: "cil-speedometer",
        //     group: null,
        //     routeName: 'Dashboard'
        // },
        // {
        //     id: 2,
        //     nama: "Pendaftaran Mahasiswa",
        //     icon: "cil-pencil",
        //     group: "transaksi",
        //     routeName: 'Pendaftaran'
        // },
        // {
        //     id: 3,
        //     nama: "Daftar Ulang",
        //     icon: "cil-pencil",
        //     group: "transaksi",
        //     routeName: 'DaftarUlang'
        // },
        // {
        //     id: 4,
        //     nama: "Isi / Ubah Data Mahasiswa",
        //     icon: "cil-pencil",
        //     group: "transaksi",
        //     routeName: 'IsiUbahDataLengkapMahasiswa'
        // },
        // {
        //     id: 5,
        //     nama: "Daftar Pendaftar",
        //     icon: "cil-pencil",
        //     group: "laporan",
        //     routeName: 'DaftarPendaftar'
        // },
        // {
        //     id: 6,
        //     nama: "Jumlah Mahasiswa Berdasarkan Sekolah",
        //     icon: "cil-pencil",
        //     group: "laporan",
        //     routeName: 'JumlahMahasiswaBerdasarkanSekolah'
        // },
        // {
        //     id: 7,
        //     nama: "Jabatan Struktural MPT",
        //     icon: "cil-pencil",
        //     group: "master mpt",
        //     routeName: 'JabatanStrukturalMpt'
        // },
        // {
        //     id: 8,
        //     nama: "Panitia MPT",
        //     icon: "cil-pencil",
        //     group: "master mpt",
        //     routeName: 'PanitiaMpt'
        // },
        // {
        //     id: 9,
        //     nama: "Grup MPT",
        //     icon: "cil-pencil",
        //     group: "master mpt",
        //     routeName: 'GrupMpt'
        // },
        // {
        //     id: 10,
        //     nama: "Generate Grup MPT",
        //     icon: "cil-pencil",
        //     group: "transaksi mpt",
        //     routeName: 'GenerateGrupMpt'
        // },
        // {
        //     id: 11,
        //     nama: "Penyesuaian Grup MPT",
        //     icon: "cil-pencil",
        //     group: "transaksi mpt",
        //     routeName: 'PenyesuaianPesertaGrupMpt'
        // },
        // {
        //     id: 12,
        //     nama: "Generate Grup Outbound MPT",
        //     icon: "cil-pencil",
        //     group: "transaksi mpt",
        //     routeName: 'GenerateGrupOutboundMpt'
        // },
        // {
        //     id: 13,
        //     nama: "Peserta Gagal MPT",
        //     icon: "cil-pencil",
        //     group: "transaksi mpt",
        //     routeName: 'PesertaMptGagal'
        // },
        // {
        //     id: 14,
        //     nama: "Absensi dan Ukuran Jas Detail MPT",
        //     icon: "cil-pencil",
        //     group: "laporan mpt",
        //     routeName: 'AbsensiUkuranJasDetail'
        // },
        // {
        //     id: 15,
        //     nama: "Gagal / Belum Ikut MPT",
        //     icon: "cil-pencil",
        //     group: "laporan mpt",
        //     routeName: 'GagalBelumIkut'
        // },
        // {
        //     id: 16,
        //     nama: "Kelulusan Peserta Mpt",
        //     icon: "cil-pencil",
        //     group: "laporan mpt",
        //     routeName: 'KelulusanPesertaMpt'
        // },
        // {
        //     id: 17,
        //     nama: "Peserta Non Grup MPT",
        //     icon: "cil-pencil",
        //     group: "laporan mpt",
        //     routeName: 'NonGrup'
        // },
        // {
        //     id: 18,
        //     nama: "Peserta MPT",
        //     icon: "cil-pencil",
        //     group: "laporan mpt",
        //     routeName: 'PesertaMpt'
        // },
        // {
        //     id: 19,
        //     nama: "Ukuran Jas Rekap Mpt",
        //     icon: "cil-pencil",
        //     group: "laporan mpt",
        //     routeName: 'UkuranJasRekap'
        // },
        // {
        //     id: 20,
        //     nama: "Ulang Tahun Peserta Mpt",
        //     icon: "cil-pencil",
        //     group: "laporan mpt",
        //     routeName: 'UlangTahunPesertaMpt'
        // },
    ]
}