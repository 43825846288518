import axios from '@/axios'

async function me() {
    let endpoint = "/ldap-me";
    let responseGetMe = await axios.get(endpoint);
    return responseGetMe
}
// login
async function login(rawInput) {
    let endpoint = "ldap-login";
    let responseLogin = await axios.post(endpoint, rawInput);
    return responseLogin
}

// refresh token(dipakai saat token expired)
async function refreshToken() {
    let endpoint = "ldap-refresh-token";
    let responseRefresh = await axios.post(endpoint);
    return responseRefresh
}

// logout
async function logout() {
    let endpoint = "ldap-logout";
    let responseLogout = await axios.post(endpoint);
    return responseLogout
}

// register staff baru
async function register(rawInput) {
    let endpoint = "register";
    let responseRegister = await axios.post(endpoint, rawInput);
    return responseRegister
}

// untuk fitur isi ubah data pribadi lengkap mahasiswa
async function registerCalonMahasiswaOnsite(formData) {
    let endpoint = `register-calon-mahasiswa-onsite`;
    let resRegisterCalonMahasiswaOnsite = await axios.post(endpoint, formData);
    return resRegisterCalonMahasiswaOnsite;
}

export default {
    me,
    login,
    refreshToken,
    logout,
    register,
    registerCalonMahasiswaOnsite,
}