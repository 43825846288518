// for auth user
import UserApi from "@/api/UserApi";
import AuthenticationApi from "@/api/AuthenticationApi.js";
import router from '@/router'

// State
const state = {
    status: '',
    token: '',
    user: {}, // with permission api
    // idUser: '',
    roles: [],
    menu: [],
    intervalRefresh: null,
}

// Getter 
const getters = {
    // getIdUser: (state) => state.idUser,
    isLoggedIn: (state) => !!state.token,
    getUser: (state) => state.user,
    getRoles: (state) => state.roles,
    authStatus: (state) => state.status,
    getToken: (state) => state.token,
    getMenu: (state) => state.menu
}


// async Fucntion
async function doLogin(commit, loginUser) {
    try {
        let responseDoLogin = await AuthenticationApi.login(loginUser)
        if (responseDoLogin.status == 200) {
            let data = responseDoLogin.data.data
            let token = data.token
            commit("SET_TOKEN", token)
            // req user
            let responseDoGetMe = await AuthenticationApi.me()
            if (responseDoGetMe.status == 200) {
                let dataGetMe = responseDoGetMe.data.data
                let {
                    id,
                    nama
                } = dataGetMe.individu
                let user = {
                    idIndividu: id,
                    nama,
                    username: dataGetMe.username
                }
                commit("SET_ME", user)
                return Promise.resolve(responseDoLogin)
            } else {
                let errorMessage = responseDoGetMe.data.error.message
                throw errorMessage
            }
            // localStorage.setItem('token', token)
        } else if (responseDoLogin.status == 500) {
            throw "Terdapat kesalahan pada server"
        } else {
            let errorMessage = responseDoLogin.data.error.message
            throw errorMessage
        }
    } catch (err) {
        commit('SET_AUTH_ERROR')
        localStorage.removeItem('vuex')
        // localStorage.removeItem('token')
        return Promise.reject(err)
    }

}

async function doRefreshToken(commit) {
    try {
        let responseDoRefreshToken = await AuthenticationApi.refreshToken()
        if (responseDoRefreshToken.status == 200) {
            let data = responseDoRefreshToken.data.data
            let token = data.token
            // let idUser = data.id_user
            commit("SET_TOKEN", token)
            // localStorage.setItem('token', token)
            return Promise.resolve(responseDoRefreshToken)
        } else if (responseDoRefreshToken.status == 500) {
            throw "Terdapat kesalahan pada server"
        } else {
            let errorMessage = responseDoRefreshToken.data.error.message
            throw errorMessage
        }
    } catch (err) {
        commit('SET_AUTH_ERROR')
        localStorage.removeItem('vuex')
        // localStorage.removeItem('token')
        return Promise.reject(err)
    }

}

async function doLogout(commit) {
    try {
        let responseDoLogout = await AuthenticationApi.logout()
        localStorage.removeItem('vuex')
        commit('SET_LOGOUT')
        if (responseDoLogout.status == 200) {
            return Promise.resolve(responseDoLogout)
        } else if (responseDoLogout.status == 422 && responseDoLogout.data.error.message == "Invalid Token.") {
            let responseMessage = {
                data: {
                    message: "Sesi anda telah habis, Mohon Login Kembali"
                }
            }
            return responseMessage
        } else {
            // return Promise.resolve(responseDoLogout)
            throw responseDoLogout
        }
    } catch (err) {
        commit('SET_AUTH_ERROR')
        return Promise.reject(err)
    }
}
async function doRequestDataUser(state, commit) {
    // get user data
    try {
        let responseGetUserData = await UserApi.getUserById(state.idUser)
        let dataUser = responseGetUserData.data.data;
        commit('SET_AUTH_SUCCESS', dataUser)
        return Promise.resolve(responseGetUserData)
    } catch (err) {
        return Promise.reject(err)
    }

}

async function doRequestRolesByUser(state, commit) {
    try {
        let responseGetRoles = await UserApi.getUserRolesByIdUser(state.idUser)
        let dataRoles = responseGetRoles.data.data;
        commit('SET_ROLES', dataRoles)
        return Promise.resolve(responseGetRoles)
    } catch (err) {
        return Promise.reject(err)
    }
}


async function doRequestMenuByUser(state, commit) {
    // get menu by id User
    try {
        let responseGetMenus = await UserApi.getMenuByIdUser(state.idUser)
        let dataMenus = responseGetMenus.data.data;
        commit('SET_MENU', dataMenus)
        return Promise.resolve(responseGetMenus)
    } catch (err) {
        return Promise.reject(err)
    }

}

async function doRequestDataUserMenu(state, commit) {
    let resDataUser = await doRequestDataUser(state, commit)
    let resMenuPermission = await doRequestMenuByUser(state, commit)

    let responseResult = {
        resDataUser,
        resMenuPermission
    }
    return Promise.resolve(responseResult)
}

// Actions
const actions = {
    // intervalRefreshToken({
    //     state,
    //     commit
    // }) {
    //     state.intervalRefresh = window.setInterval(() => {
    //         console.log("Refresh Token")
    //         doRefreshToken(commit).catch(() => {
    //             clearInterval(state.intervalRefresh)
    //             if (state.token) {
    //                 commit('SET_AUTH_REQUEST')
    //                 doLogout(commit).then(() => {
    //                     if (router.currentRoute.name != "Login") {
    //                         router.replace({
    //                             name: "Login",
    //                         });
    //                         router.app.flash("Sesi anda telah habis, Mohon Login Kembali", "warning")
    //                     }
    //                 })
    //             } else {
    //                 if (router.currentRoute.name != "Login") {
    //                     router.replace({
    //                         name: "Login",
    //                     });
    //                     router.app.flash("Sesi anda telah habis, Mohon Login Kembali", "warning")
    //                 }
    //             }

    //         })
    //     }, 15000)
    // },
    // clearIntervalRefresh({
    //     state
    // }) {
    //     clearInterval(state.intervalRefresh)
    // },
    requestDataRoles({
        state,
        commit
    }) {
        return new Promise((resolve, reject) => {
            try {
                resolve(doRequestRolesByUser(state, commit))
            } catch (err) {
                reject(err)
            }
        })
    },
    requestDataUserMenu({
        state,
        commit
    }) {
        return new Promise((resolve, reject) => {
            try {
                resolve(doRequestDataUserMenu(state, commit))
            } catch (err) {
                reject(err)
            }
        })
    },
    login({
        commit
    }, loginUser) {
        commit('SET_AUTH_REQUEST')
        return doLogin(commit, loginUser)
    },

    logout({
        commit
    }) {
        commit('SET_AUTH_REQUEST')
        return doLogout(commit)
    },

    refresh({
        commit
    }) {
        return doRefreshToken(commit)
    },

    register({
        state,
        commit
    }, regisData) {
        return new Promise((resolve, reject) => {
            commit('SET_AUTH_REQUEST')
            AuthenticationApi.register(regisData)
                .then(res => {
                    state.status = "success"
                    //route to login 
                    // router.push({
                    //     name: "Login"
                    // })
                    resolve(res)
                })
                .catch(err => {
                    state.status = "error"
                    reject(err)
                })
        })
    }
}

// Mutations
const mutations = {
    SET_AUTH_REQUEST(state) {
        state.status = 'loading';
    },
    SET_ME(state, user) {
        state.user = user
    },
    SET_TOKEN(state, token) {
        state.status = 'success';
        state.token = token;
    },

    SET_AUTH_SUCCESS(state, user) {
        state.status = 'success';
        state.user = user;
    },

    SET_AUTH_ERROR(state) {
        state.status = 'error';
    },

    SET_ROLES(state, roles) {
        // fill roles
        state.roles = roles
    },

    SET_MENU(state, dataMenu) {
        // fill Menu
        state.menu = dataMenu
    },

    // SET_MENU_ROLES_PERMISSION(state) {
    //     // loop all roles
    //     state.roles.forEach(role => {
    //         // loop all menu
    //         menuRolesPermission[role].idMenuRolesPermission.forEach(menu => {
    //             if (state.idMenuRolesPermission.length === 0 ||
    //                 !(state.idMenuRolesPermission.find(it => it.id == menu.id))) {
    //                 // isi 
    //                 state.idMenuRolesPermission.push({
    //                     ...menu
    //                 });
    //             } else {
    //                 // update permission fitur or kan semua
    //                 state.idMenuRolesPermission.map(function (it) {
    //                     if (it.id === menu.id) {
    //                         // loop all permission do OR opertation
    //                         for (var permissionName in menu.permission) {
    //                             it.permission[permissionName] = it.permission[permissionName] || menu.permission[permissionName];
    //                         }
    //                     }
    //                 })
    //             }
    //         })
    //     })
    // },

    SET_LOGOUT(state) {
        // localStorage.removeItem('token')
        state.status = '';
        state.token = '';
        state.roles = []
        state.user = {}
        state.idMenuRolesPermission = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}