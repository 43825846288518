import Vue from 'vue'
import KonfigurasiApi from "@/api/KonfigurasiApi.js";
import KonfigurasiBerjalanApi from "@/api/KonfigurasiBerjalanApi.js";

const moment = require("moment");

Vue.mixin({
    data() {
        return {
            mixinArrayAgama: [{
                id: 0,
                nama: "-"
            },
            {
                id: 1,
                nama: "Islam"
            },
            {
                id: 2,
                nama: "Kristen Protestan"
            },
            {
                id: 3,
                nama: "Kristen Katolik"
            },
            {
                id: 4,
                nama: "Hindu"
            },
            {
                id: 5,
                nama: "Buddha"
            },
            {
                id: 6,
                nama: "Konghucu"
            }
            ],
        }
    },
    methods: {
        $_generalMixin_getNamaAgamaById: function (idAgama) {
            return this.mixinArrayAgama.find((item) => item.id == idAgama).nama;
        },
        // jenjang = 1 (S1), jenjang = 2(S2)
        $_generalMixin_getTahunAjaranBerjalan: async function (jenjang) {
            try {
                let resKonfigurasiTABerjalan = await KonfigurasiBerjalanApi.getKonfigurasiTahunAjaranBerjalan();
                let idKonfigurasi = resKonfigurasiTABerjalan.data.data[`id_konfigurasi_s${jenjang}`]
                let resKonfigurasi = await KonfigurasiApi.getKonfigurasiTahunAjaranByJenjangAndIdKonfigurasi(jenjang, idKonfigurasi)
                return resKonfigurasi.data.data.tahun_ajaran
            } catch (err) {
                this.flash("Error Get TA Berjalan : " + err, "error")
                return "2017/2018"
            }
        },
        $_generalMixin_setIsLoading: function (boolIsLoading) {
            this.$store.dispatch('dataComponent/set', ["isLoading", boolIsLoading])
        },
        $_generalMixin_cekObjEmpty: function (obj) {
            if (obj === undefined || obj === null) {
                return true
            } else {
                return Object.keys(obj).length === 0 && obj.constructor === Object
            }
        },
        $_generalMixin_printHtmlById: function (htmlId) {
            const prtHtml = document.getElementById(htmlId).innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = "";
            for (const node of [
                ...document.querySelectorAll('link[rel="stylesheet"], style')
            ]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            const WinPrint = window.open(
                "",
                "",
                "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
            );

            WinPrint.document.write(`<!DOCTYPE html>
            <html>
              <head>
                ${stylesHtml}
              </head>
              <body>
                ${prtHtml}
              </body>
            </html>`);

            WinPrint.document.close();
            var is_chrome = Boolean(WinPrint.chrome);

            if (is_chrome) {
                WinPrint.onload = function () {
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                };
            } else {
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
            }
        },
        $_generalMixin_convertMoney: function (number) {
            let number_string = number.toString(),
                sisa = number_string.length % 3,
                rupiah = number_string.substr(0, sisa),
                ribuan = number_string.substr(sisa).match(/\d{3}/g);

            if (ribuan) {
                let separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }
            return `Rp. ${rupiah}`
        },
        // params format optional
        $_generalMixin_convertTanggal(tanggal, formatTanggal) {
            if (!formatTanggal) {
                formatTanggal = "YYYY-MM-DD"
            }
            let res = moment(tanggal).format(formatTanggal);
            return res;
        },
        $_generalMixin_deleteInputFile: function () {
            let arrayInputFile = document.querySelectorAll("input[type=file]");
            for (let inputFile of arrayInputFile) {
                inputFile.value = "";
            }
        },
    }
})