import axios from '@/axios';
// jenjang = 1 => S1, jenjang = 2 => S2
async function getAllKonfigurasiTahunAjaranByJenjang(jenjang) {
    let endpoint = `konfigurasi-s${jenjang}?limit=all`;
    let responseKonfigurasiTahunAjaran = await axios.get(endpoint)
    return responseKonfigurasiTahunAjaran
}

async function getKonfigurasiTahunAjaranByJenjangAndIdKonfigurasi(jenjang, idKonfigurasi) {
    let endpoint = `konfigurasi-s${jenjang}/${idKonfigurasi}`
    let responseKonfigurasiTahunAjaran = await axios.get(endpoint)
    return responseKonfigurasiTahunAjaran
}

export default {
    getAllKonfigurasiTahunAjaranByJenjang,
    getKonfigurasiTahunAjaranByJenjangAndIdKonfigurasi
}